body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div {
  box-sizing: border-box; 
}

.flex-delimiter {
  flex-grow: 1;
}

.app {
  width: 100vw; 
  min-height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
} 


  .app-container {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 70px 0;
    position: relative;
    
  }

  .app-container-fixed {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 70px 0;
    position: fixed;
    overflow-y: hidden;
  }

  .app-story-box {
    display: block;
    max-width: 100%;
    position: relative;
  }
  
  .app-story-box-picker {
      display: block;
      overflow-x: scroll;
      white-space: nowrap;
      margin-bottom: 16px;
  }
  
  .app-story-type-button {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 2px 4px;
    
  }
  
  .app-story-type-button-selected {
    display: inline-block;
    position: relative;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    border: 2px solid #1d90ff;
    margin: 0px 2px;
  }

  .app-popup {
    width: 100vw; 
    min-height: 100vh;
    overflow-x: hidden;
    position: fixed;
    margin: 0 auto;
    }
    
  .popup-up{
    position: fixed;
    width: 100%; 
    height: 80%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) ;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 1.2s ease 0s;
    }
    
    .popup-box {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding: 12px;
      color: white;
      font-size: 24px;
      text-align: center;
    }

    .openlink-button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 12px;
      padding: 2px 6px;
      border-radius: 4px;
      box-shadow: 0 0 0 0 white;
      transform: scale(1);
      animation: pulse 2s infinite;
      cursor: pointer;
    }
    .push {
      display: block;
      width: 65px;
      height: 65px;
      margin-left: 75px;
      border-right: 12px solid #1d90ff;
      border-bottom: 12px solid #1d90ff;
      transform: rotate(45deg);
  
      animation: downUp 2s infinite ease-in-out; /* включаем анимацию и задаем параметры */
  }
  
  
  /* анимация */
  @keyframes downUp { 
    from {margin-top: 190px;}  
    50% {margin-top: 50px;}
    to {margin-top: 190px;}
  }
  
    




  .popup-down{
   position: fixed;
   width: 100%;
    height: 21%;
    bottom: 0;
   background-color: black ;
  
    left: 0;
    // opacity: 0;
    // visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 1.2s ease 0s;
    }

    
    

.constructor-link {
  height: 60px;
  margin: 24px 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  
  svg {
    font-size: 60px;
    margin-right: 12px;
  }
}

.main-page-logoBox {
  margin-top: -15px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;

  &__logo {
    width: 120px;
    height: 120px;

    background: {
      size: cover;
      repeat: no-repeat;
      position: center bottom;
      }
  }
}

.main-page {
  background-color: blue;
  top: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  max-width: 600px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  

  
  &__header {
    color: #fff;
    background: transparent;
    position: absolute;
    top: 40;
    left: 0;
    z-index: 3;
    text-align: center;
    width: 85px;
    height: 40px;
    
  }
  
  &__page1 {
    height: 100%;
    width: 100%;
    position: relative;
    background: {
    size: cover;
    repeat: no-repeat;
    position: center bottom
    
    }

    &__h1{
      margin: 0;
      font-size: 30px;
      text-align: left;
      padding: 0 12px;
      line-height: 1.0em;
      font-weight: normal;
      text-transform: uppercase;
      white-space: nowrap;
  
    }
    
    &__h2 {
    margin: 12px;
    font-size: 26px;
    text-align: left;
    padding: 0 12px;
    line-height: 1.0em;
    font-weight: normal;
    text-transform: uppercase;
    }

    &__h3 {
      margin: 12px;
      font-size: 22px;
      text-align: left;
      line-height: 1.0em;
      font-weight: normal;
      text-transform: uppercase;
      white-space: nowrap;
      }
    
    &__container-anime {
      position: absolute;
      z-index: 2;
      height: auto;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;

      &__button-slide7-flexBox {
        position: fixed;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        bottom: 9%;
        left: 0;

        &__button-slide7 {
            position: relative;
            display: inline-block;
            color: #7e2e7e;
            font-weight: bold;
            text-decoration: none;
            text-shadow: rgba(255,255,255,.5) 1px 1px, rgba(100,100,100,.3) 3px 7px 3px;
            user-select: none;
            padding: 0.5em 1.6em;
            outline: none;
            border-radius: 3px / 100%;
            background-image:
             linear-gradient(45deg, rgba(255,255,255,.0) 30%, rgba(255,255,255,.8), rgba(255,255,255,.0) 70%),
             linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0) 20%, rgba(255,255,255,0) 90%, rgba(255,255,255,.3)),
             linear-gradient(to right, rgba(125,125,125,1), rgba(255,255,255,.9) 45%, rgba(125,125,125,.5)),
             linear-gradient(to right, rgba(125,125,125,1), rgba(255,255,255,.9) 45%, rgba(125,125,125,.5)),
             linear-gradient(to right, rgba(223,190,170,1), rgba(255,255,255,.9) 45%, rgba(223,190,170,.5)),
             linear-gradient(to right, rgba(223,190,170,1), rgba(255,255,255,.9) 45%, rgba(223,190,170,.5));
            background-repeat: no-repeat;
            background-size: 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
            background-position: 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
            box-shadow: rgba(0,0,0,.5) 3px 10px 10px -10px;
          }
          .button-slide7:hover {
            transition: .5s linear;
            background-position: -200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
          }
          .button-slide7:active {
            top: 1px;
          }
          
        } 
       


      &__text1-flexBox {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        &__text1 {
          width: 240px;
          text-align: center;
          color: white;
          font-size: 22px;
          font-weight: 600px;
          line-height: 1.1;
          font-family: 'Rubik Mono One', sans-serif;

          h1 {
          text-align: center;
          color: white;
          font-size: 20px;
          font-weight: 400px;

          }

        }

        &__text2 {
          padding-top: 11%;
          width: 270px;
          text-align: center;
          color: white;
          font-size: 22px;
          font-weight: 600px;
          line-height: 1.1;
          font-family: 'Rubik Mono One', sans-serif;
          margin-bottom: 12px;
        }



        &__text3 {
          width: 270px;
          padding-top: 4px;
          text-align: center;
          color: white;
          font-size: 10px;
          font-family: 'Manrope', sans-serif;
          line-height: 0.9;
          margin-bottom: 4px;
          border-top: 1px solid white;
        }
      }
    }

    &__container-anime-center {
      display: flex;
      flex-wrap: nowrap;
      flex-flow: row;
      z-index: 2;
      max-width: 100%;
      margin-top: 30%;
    }

    &__container-anime-dilimeter {
      z-index: 2;
      width: 50%;
    }
    
    &__container-anime-box-image {
      z-index: 2;
      text-align: center;

      }
    

    &__container1 {
      position: absolute;
      z-index: 2;
      height: 95%;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &__header {
      color: #fff;
      font-size: 24px;
      text-align: center;
      margin-bottom: 12px;
      padding: 12px 14px 0;
      line-height: 1.1em;
      font-weight: normal;
    }
    
    &__subtitle {
      color: #fff;
      font-size: 12px;
      text-align: left;
      font-weight: normal;
      padding: 0 18px 18px;
      line-height: 1.0em;
    }
    
    a {
        color: #fff; 
      } 

      p {
        color: #fff;
        font-size: 18px;
      } 
      
    &__container2 {
      position: absolute;
      z-index: 2;
      height: 75%;
      bottom: 0;
      left: 0;
      width: 100%;
      

    }
  }
  
  &__page {
    position: relative;
    padding: 0 12px;
    img {
      width: calc(100% - 150px);
      max-width: 300px;
      margin: 40px auto 0;
      display: block;
    }

    p {
      color: #ff0000;
      font-weight: bold;
    }
    
    &__container {
      position: absolute;
      z-index: 2;
      height: 65%;
      bottom: 0;
      left: 0;
    }
    
    &__header {
      color: #fff;
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
      line-height: 1.1em;
    }
    
    &__subtitle {
      color: #fff;
      font-size: 18px;
      line-height: 1.0em;
      text-align: center;
      font-weight: normal;
      padding: 0 24px;
    }
  }
  
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    padding: 6px;

    .button {
      width: 100%;
      background-color: red;
      text-transform: uppercase;
    }
  }
  
  &__pages {
    display: flex;
    flex-flow: row nowrap;
    padding: 12px 48px;

    div {
      flex-grow: 1;
      margin: 3px;
      height: 4px;
      background-color: rgba(255, 255, 255, .3);
      border-radius: 1px;
    }
    
    &__selected {
      background-color: rgba(255, 255, 255, .8) !important;
    }
  }
}

.swiper-container {
  height: 100%;
}

.video {
  position:relative;
  padding-bottom:56.25%;
  padding-top:25px;height:0;
}

.video iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.center {
  margin-left: 50%;
}

.cookie-box {
  width: 100%;
  position: fixed;
  z-index: 100000;
  bottom: 10px;
  left: 0;
  
}

.textlogolanding {
  font-size: 8px;
  color: white;
  text-align: center;
  
}

.subtitle-slide7 {
  color: white;
  font-size: 8px;
  text-align: center;
}

.title-theme4-box {
  position: absolute;
  z-index: 2;
  height: 65%;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-brends-box-items-landing {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 12px 30% -12px;
}

.empty-box {
  height: 44px;
}

.settings-video-link-box {

  &__flexbox {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;

    &__icon {
    color: red;
    }

    &__text {
    padding-left: 6px;
    }
  }
}

.finger-popup{
  position: fixed;
  width: 100%;
  height: 115%;
  top: -90px;
  background-color:  rgba(0, 0, 0, 0.7) ;
  left: 0;
   // opacity: 0;
   // visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  opacity:0; /*Элемент полностью прозрачный (невидимый)*/
  transition: 1.4s; /*Скорость перехода состояния элемента*/
  animation: show 3s 1; /* Указываем название анимации, её время и количество повторов*/
  animation-fill-mode: forwards; 
  animation-delay: 0.5s;

  background: {
  background-size: 40%;
  repeat: no-repeat;
  position: center center;
   }
  }

  





@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&family=Rubik+Mono+One&display=swap');
